exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-statement-tsx": () => import("./../../../src/pages/accessibility-statement.tsx" /* webpackChunkName: "component---src-pages-accessibility-statement-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookie-confirmation-tsx": () => import("./../../../src/pages/cookie-confirmation.tsx" /* webpackChunkName: "component---src-pages-cookie-confirmation-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-definitions-and-notes-about-this-data-tsx": () => import("./../../../src/pages/definitions-and-notes-about-this-data.tsx" /* webpackChunkName: "component---src-pages-definitions-and-notes-about-this-data-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-national-gp-2-gp-statistics-tsx": () => import("./../../../src/pages/national-gp2gp-statistics.tsx" /* webpackChunkName: "component---src-pages-national-gp-2-gp-statistics-tsx" */),
  "component---src-pages-sub-icb-locations-tsx": () => import("./../../../src/pages/sub-ICB-locations.tsx" /* webpackChunkName: "component---src-pages-sub-icb-locations-tsx" */),
  "component---src-pages-your-privacy-tsx": () => import("./../../../src/pages/your-privacy.tsx" /* webpackChunkName: "component---src-pages-your-privacy-tsx" */),
  "component---src-templates-download-data-practice-download-data-index-tsx": () => import("./../../../src/templates/DownloadData/PracticeDownloadData/index.tsx" /* webpackChunkName: "component---src-templates-download-data-practice-download-data-index-tsx" */),
  "component---src-templates-download-data-sicbl-download-data-index-tsx": () => import("./../../../src/templates/DownloadData/SICBLDownloadData/index.tsx" /* webpackChunkName: "component---src-templates-download-data-sicbl-download-data-index-tsx" */),
  "component---src-templates-integration-times-practice-integration-times-index-tsx": () => import("./../../../src/templates/IntegrationTimes/PracticeIntegrationTimes/index.tsx" /* webpackChunkName: "component---src-templates-integration-times-practice-integration-times-index-tsx" */),
  "component---src-templates-integration-times-sicbl-integration-times-index-tsx": () => import("./../../../src/templates/IntegrationTimes/SICBLIntegrationTimes/index.tsx" /* webpackChunkName: "component---src-templates-integration-times-sicbl-integration-times-index-tsx" */),
  "component---src-templates-transfers-requested-practice-transfers-requested-index-tsx": () => import("./../../../src/templates/TransfersRequested/PracticeTransfersRequested/index.tsx" /* webpackChunkName: "component---src-templates-transfers-requested-practice-transfers-requested-index-tsx" */),
  "component---src-templates-transfers-requested-sicbl-transfers-requested-index-tsx": () => import("./../../../src/templates/TransfersRequested/SICBLTransfersRequested/index.tsx" /* webpackChunkName: "component---src-templates-transfers-requested-sicbl-transfers-requested-index-tsx" */)
}

